import {
  Button,
  Flex,
  LightMode,
  Stack,
  Textarea,
  Select,
} from "@chakra-ui/react"
import * as React from "react"
import { InputField } from "./InputField"

const CareersForm = () => {
  return (
    <form
      // onSubmit={(e) => {
      //   // add submit logic here
      //   e.preventDefault()
      // }}
      method="post"
      action="https://getform.io/f/dde338d7-12c4-4fb8-b31f-f30f057755e6"
    >
      <Stack spacing="8">
        <InputField label="Name" type="text" name="name" />
        <InputField label="Email" type="email" name="email" />
        <InputField label="Phone" type="text" name="phone" />
        <InputField
          label="How did you hear about us?"
          type="text"
          name="source"
        />
        <Select
          placeholder="Are you looking for Full-time or Part-time work?"
          name="employment"
        >
          <option value="full-time">Full Time</option>
          <option value="part-time">Part Time</option>
        </Select>
        <Select placeholder="Do you prefer email or text?" name="contact">
          <option value="email">Email</option>
          <option value="text">Text</option>
        </Select>
        <Textarea
          placeholder="Do you have any relevant work experience?"
          name="experience"
        />
      </Stack>
      <Flex
        spacing="4"
        direction={{ base: "column-reverse", md: "row" }}
        mt="6"
        align="center"
        justify="space-between"
      >
        <LightMode>
          <Button
            mb={{ base: "4", md: "0" }}
            w={{ base: "full", md: "auto" }}
            type="submit"
            colorScheme="bullseyeOrange"
            size="lg"
            fontSize="md"
            fontWeight="bold"
          >
            Submit
          </Button>
        </LightMode>
      </Flex>
    </form>
  )
}

export default CareersForm
