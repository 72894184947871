import React from "react"
import {
  Box,
  Heading,
  SimpleGrid,
  Flex,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react"
import { GiRake, GiHighGrass, GiBrickPile, GiMineTruck } from "react-icons/gi"
import { StaticImage } from "gatsby-plugin-image"
import { CTAButton } from "../../components/landingpage/CTAButton"
import { Feature2 } from "../../components/landingpage/Feature2"
import Layout from "../../components/Layout"
import Meta from "../../components/Meta"
import AccordionFAQ from "../../components/landingpage/AccordionFAQ"
import CareersForm from "../../components/forms/CareersForm"

const Careers = () => {
  const questions = [
    {
      id: "q1",
      heading: "About Bullseye Lawn Care",
      text:
        "Bullseye Lawn Care provides lawn care services to customers in the Sioux Falls and surrounding areas. We take pride in the quality of our work and care about our customers. We are a growing company looking for others to help us in our journey to show our customers what a Bullseye Lawn looks like!",
    },
    {
      id: "q2",
      heading: "Why we're hiring",
      text:
        "We need seasonal help! Right now we have seasonal opportunities with full-time or part-time hours available. While this will originally only be a seasonal position, we are looking for individuals that may be key contributors in the future as our company continues to grow.",
    },
    {
      id: "q3",
      heading: "Required experience",
      text:
        "We prefer candidates with past lawn care experience, however, we can provide some training for the right individuals.",
    },
    {
      id: "q4",
      heading: "Starting wage",
      text:
        "The starting wage is dependent on experience but starts at $15 per hour.",
    },
    {
      id: "q5",
      heading: "Details of the role",
      text:
        "This is a seasonal position with full-time or part-time hours available. The hours can be flexible depending on your experience and schedule. You will be working around the Sioux Falls area and will need a valid drivers license.",
    },
    {
      id: "q6",
      heading: "Equal Opportunity Statement",
      text:
        "Bullseye Lawn Care is an equal opportunity employer and we acknowledge and honor the fundamental value and dignity of all individuals. We commit ourselves to creating and maintaining an environment that respects diverse heritages and experiences, and seek applicants of diverse backgrounds and hire without regard to race, color, gender identity, religion, national origin, ancestry, citizenship, physical abilities (or disability), age, sexual orientation, veteran status, or any other characteristic protected by law.",
    },
  ]

  return (
    <Layout>
      <Meta
        title="Now Hiring: Lawn Professional"
        description="Bullseye Lawn Care is hiring for a Lawn Professional."
      />
      <Box as="section">
        <Box
          maxW={{ base: "xl", md: "7xl" }}
          minH="700px"
          mx="auto"
          px={{ base: "6", md: "8" }}
          py="20"
        >
          <Flex
            align="center"
            justify="center"
            direction={{ base: "column-reverse", md: "row" }}
          >
            <Box flex="1" maxW="440px">
              <Heading
                as="h1"
                size="2xl"
                fontWeight="extrabold"
                color={useColorModeValue(
                  "bullseyeGreen.600",
                  "bullseyeGreen.300"
                )}
              >
                Now Hiring: Part/Full-time Lawn Professional
              </Heading>
              <Text
                fontSize="lg"
                fontWeight="medium"
                color={useColorModeValue("gray.600", "inherit")}
                mt="6"
              >
                Bullseye Lawn Care is now hiring part-time and full-time lawn
                professionals to join our growing team. We are looking for
                dependable individuals that care about quality and customer
                service. If this sounds like you, keep reading to learn more
                about the job.
              </Text>
              <CTAButton
                w={{ base: "full", md: "auto" }}
                bg="bullseyeGreen.600"
                _hover={{ bg: "bullseyeGreen.400" }}
                color="white"
                mt="12"
              >
                Start a career with Bullseye
              </CTAButton>
            </Box>

            <Box
              aria-hidden
              className="spacer"
              flexShrink={0}
              boxSize={{ base: "10", xl: "20" }}
            />

            <Box flex="1" maxW="560px" h={{ base: "400px", md: "460px" }}>
              <StaticImage
                src="../../images/lawn-backyard.jpg"
                alt="Lawn Care example"
                placeholder="blurred"
                aspectRatio={1}
              />
            </Box>
          </Flex>
        </Box>
      </Box>
      <Box as="section" pb="24">
        <Box bg="bullseyeGreen.600" color="white" pt="24" pb="12rem">
          <Box
            maxW={{ base: "xl", md: "7xl" }}
            mx="auto"
            px={{ base: "6", md: "8" }}
          >
            <Stack
              spacing="10"
              direction={{ base: "column", lg: "row" }}
              align={{ base: "flex-start", lg: "center" }}
              justify="space-between"
            >
              <Heading
                size="2xl"
                lineHeight="short"
                fontWeight="extrabold"
                maxW={{ base: "unset", lg: "800px" }}
              >
                What Does a Bullseye Lawn Pro Do?
              </Heading>
              <CTAButton w={{ base: "full", md: "auto" }}>
                I'm Interested
              </CTAButton>
            </Stack>
            <SimpleGrid
              columns={{ base: 1, md: 2, lg: 4 }}
              spacing={{ base: "12", md: "8", lg: "2" }}
              mt={{ base: "12", md: "20" }}
            >
              <Feature2 icon={<GiHighGrass />} title="Lawn Mowing">
                Lawn Pros are responsible for mowing and edging customer
                properties. This includes using riding lawn mowers, push mowers,
                weed wackers, and edgers.
              </Feature2>
              <Feature2 icon={<GiRake />} title="Spring & Fall Cleanups">
                Lawn Pros provide Spring and Fall cleanups. This includes using
                leaf blowers and other tools to get a customers yard looking
                great for the changing seasons.
              </Feature2>
              <Feature2 icon={<GiBrickPile />} title="Light Landscaping">
                Lawn Pros do some light landscape work. This often means
                cleaning up landscaping, trimming/pruning bushes, and
                occasionally doing small rock installs.
              </Feature2>
              <Feature2 icon={<GiMineTruck />} title="Debris Removal">
                Lawn Pros are responsible for hauling away all the debris from a
                job site. This means loading/unloading the leaves, grass, and
                any other debris from a customers yard.
              </Feature2>
            </SimpleGrid>
          </Box>
        </Box>
      </Box>
      <Box as="section" py="24">
        <Box
          maxW={{ base: "xl", md: "7xl" }}
          mx="auto"
          px={{ base: "6", md: "8" }}
        >
          <Flex
            direction={{ base: "column", lg: "row" }}
            justify="space-between"
          >
            <Box flex="1" maxW={{ lg: "xl" }}>
              <StaticImage
                src="../../images/bullseyelawnexamplecircles.jpg"
                alt="Rake with lawn debris"
                placeholder="blurred"
                aspectRatio={1}
              />
            </Box>
            <Box
              ms={{ lg: "12" }}
              mt={{ base: "12", lg: 0 }}
              flex="1"
              maxW={{ lg: "xl" }}
            >
              <Box maxW={{ base: "xl", lg: "unset" }}>
                <Box
                  as="b"
                  color={useColorModeValue(
                    "bullseyeGreen.500",
                    "bullseyeGreen.300"
                  )}
                >
                  Join a growing team of lawn professionals
                </Box>
                <Heading
                  mt="4"
                  mb="5"
                  size="2xl"
                  fontWeight="extrabold"
                  lineHeight="1.2"
                >
                  Start a career at Bullseye Lawn Care
                </Heading>
                <Text
                  fontSize="lg"
                  color={useColorModeValue("gray.600", "gray.400")}
                >
                  Are you interested in learning more about a career with
                  Bullseye Lawn Care?
                </Text>
              </Box>
              <AccordionFAQ items={questions} mt={{ base: "5", lg: "5" }} />
            </Box>
          </Flex>
        </Box>
      </Box>
      <Box
        as="section"
        bg={useColorModeValue("gray.50", "gray.700")}
        id="lead-form"
        pt="10"
      >
        <Box
          maxW="3xl"
          mx="auto"
          px={{ base: "6", lg: "8" }}
          py={{ base: "16", sm: "20" }}
          textAlign="center"
        >
          <Box>
            <Heading
              color={useColorModeValue(
                "bullseyeGreen.600",
                "bullseyeGreen.400"
              )}
              as="h1"
              size="2xl"
              fontWeight="extrabold"
              letterSpacing="tight"
            >
              Interested in working for Bullseye?
            </Heading>
            <Text
              mt="3"
              fontSize={{ base: "xl", md: "2xl" }}
              fontWeight="bold"
              color={useColorModeValue("gray.500", "inherit")}
            >
              Fill out the form below and we will contact you to set up a time
              for us to talk.
            </Text>
          </Box>

          <Box
            minW={{ md: "420px" }}
            maxW="xl"
            mt="10"
            mx="auto"
            rounded="xl"
            bg={{ md: useColorModeValue("white", "gray.600") }}
            shadow={{ md: "lg" }}
            px={{ md: "10" }}
            pt={{ base: "8", md: "12" }}
            pb="8"
          >
            <CareersForm />
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}

export default Careers
